import React from 'react';
import { Link } from 'react-router-dom';
import Container from '../../components/manage-landing-components/Container';
import Title from '../../components/manage-landing-components/Title';
import styled from 'styled-components';
import busImg from "../../assets/icons/bus.svg";
import { useTranslation, withTranslation } from 'react-i18next';
import GlobalPhotoUpload from "../../components/manage-landing-components/GlobalPhotoUpload";
import BusTable from '../../components/manage-landing-components/BusTable';

const BackButton = styled(Link)`
  color: #031F73; /* You can choose the color you want for the link text */
  font-size: 0.75rem;
  font-weight: 700;
  display: flex;
  align-items: center; 
  margin-bottom: 0.5rem; 
`;

const ArrowIcon = styled.span`
  font-size: 0.75rem;
`;

const Divider = styled.div`
  display: flex;
  flex-grow: 1;
  justify-content: space-between;
  gap: 2rem;
`;

const Table = styled.div`
  flex: 2; 

`;

const Map = styled.div`
  flex: 1;
  align-items:center;
  display:flex;
  justify-content:center;
`;


const StyledImg=styled.img`
opacity:0.5`
const Bus = () => {
  const { t,i18n } = useTranslation();
  return (
    <Container>
      <BackButton to={`/${i18n.language}/index/manage-landing-page`}>
        <ArrowIcon>←</ArrowIcon> {t("back")}
      </BackButton>
      <Title text={t("bus")}/>
      <Divider>
        <Table>

         <BusTable/>
        </Table>
        <Map>
        <GlobalPhotoUpload type_name="bus" />
        </Map>
      </Divider>
    </Container>
  );
};

export default Bus;