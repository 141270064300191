import React from "react";
import Pagination from "react-bootstrap/Pagination";
import "./Pagination.css";

function PaginationTest({ currentPage, totalPages, onPageChange }) {
    const paginationItemStyle = {
        width: "39px",
        height: "39px",
        margin: "0 5px",
    };

    const visiblePages = 10;

    const generatePaginationItems = () => {
        const items = [];

        // Previous Button
        items.push(
            <Pagination.Prev
                key="prev"
                onClick={() => onPageChange(currentPage - 1)}
                disabled={currentPage === 1}
                style={paginationItemStyle}
            />
        );

        let startPage = Math.max(1, currentPage - Math.floor(visiblePages / 2));
        let endPage = Math.min(totalPages, startPage + visiblePages - 1);

        if (endPage - startPage + 1 < visiblePages) {
            startPage = Math.max(1, endPage - visiblePages + 1);
        }

        if (startPage > 1) {
            items.push(
                <Pagination.Item key={1} onClick={() => onPageChange(1)} style={paginationItemStyle}>
                    1
                </Pagination.Item>
            );

            if (startPage > 2) {
                items.push(<Pagination.Ellipsis key="ellipsis-start" disabled />);
            }
        }

        for (let i = startPage; i <= endPage; i++) {
            items.push(
                <Pagination.Item
                    key={i}
                    active={i === currentPage}
                    onClick={() => onPageChange(i)}
                    style={paginationItemStyle}
                >
                    {i}
                </Pagination.Item>
            );
        }

        if (endPage < totalPages) {
            if (endPage < totalPages - 1) {
                items.push(<Pagination.Ellipsis key="ellipsis-end" disabled />);
            }

            items.push(
                <Pagination.Item key={totalPages} onClick={() => onPageChange(totalPages)} style={paginationItemStyle}>
                    {totalPages}
                </Pagination.Item>
            );
        }

        // Next Button
        items.push(
            <Pagination.Next
                key="next"
                onClick={() => onPageChange(currentPage + 1)}
                disabled={currentPage === totalPages}
                style={paginationItemStyle}
            />
        );

        return items;
    };

    return <Pagination>{generatePaginationItems()}</Pagination>;
}

export default PaginationTest;

